import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		container: {
			position: 'relative',
			display: 'grid',
			gap: theme.spacing(2.5),
			backgroundColor: theme.palette.common.white,
			padding: theme.spacing(3),
			...theme.border({ radius: 12, color: 'transparent' }),

			[theme.breakpoints.up('sm')]: {
				gap: theme.spacing(3),
				gridTemplateColumns: 'repeat(2, auto)',
				gridTemplateRows: 'repeat(3, auto)'
			}
		},
		titleContainer: {
			display: 'flex',
			flexDirection: 'column',
			textDecoration: 'none',
			[theme.breakpoints.up('sm')]: {
				gridRow: '1/1',
				gridColumn: '1/1'
			},
			'&:hover': {
				textDecoration: 'underline',
				textDecorationColor: 'black'
			}
		},
		subtitleText: {
			color: '#59748D'
		},
		progressContainer: {
			[theme.breakpoints.up('sm')]: {
				justifySelf: 'flex-end',
				flexDirection: 'row-reverse',
				gridRow: '1/1',
				gridColumn: '2/2'
			}
		},
		currentContentContainer: {
			[theme.breakpoints.up('sm')]: {
				gridRow: '3/3',
				gridColumn: '1/1'
			}
		},
		actionButtonContainer: {
			justifySelf: 'flex-end',

			[theme.breakpoints.up('sm')]: {
				alignSelf: 'flex-end',

				gridRow: '3/3',
				gridColumn: '2/2'
			}
		},
		actionButton: {},
		actionButtonNotStarted: {
			background: theme.palette.success['500'],
			color: theme.palette.common.white
		},
		actionButtonTitle: {
			textTransform: 'none'
		},
		actionButtonIcon: {
			width: 16,
			height: 16
		},
		notStarted: {},
		compact: {
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			gap: theme.spacing(2.5),

			// padding 24px + button 42px + gap 20px
			paddingBottom: 24 + 42 + 20,

			'& $titleContainer, & $progressContainer': {
				width: '100%'
			},

			'& $progressContainer': {
				flexDirection: 'row-reverse',
				justifySelf: 'flex-start',
				justifyContent: 'flex-end'
			},

			'& $currentContent': {
				flexDirection: 'column'
			},

			'& $actionButtonContainer': {
				// I wasn't able to find a way to clip it to the bottom with flex or grid
				position: 'absolute',
				left: theme.spacing(3),
				right: theme.spacing(3),
				bottom: theme.spacing(3),
				'& $actionButton': {
					width: '100%'
				}
			}
		},
		currentContent: {
			paddingLeft: 0
		}
	}),
	{
		name: 'AddonCard',
		// index to override Button styles
		index: 1
	}
);

export default useStyles;
