import React from 'react';

import { AspectRatio } from 'common';
import { Typography } from 'components';
import { Box } from '@mui/material';
import useStyles from './LectureThumbnail.styles';

type LectureThumbnailProps = {
	thumbnailUrl: string | null | undefined;
	duration?: string | null;
};

export const LectureThumbnail = (props: LectureThumbnailProps) => {
	const { thumbnailUrl, duration } = props;
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<AspectRatio>
				{thumbnailUrl ? (
					<img
						src={thumbnailUrl}
						width="100%"
						height="100%"
						style={{ borderRadius: 3 }}
					/>
				) : (
					<div className={classes.fallbackContainer} />
				)}
			</AspectRatio>
			{duration && <Typography className={classes.duration} title={duration} />}
		</Box>
	);
};
