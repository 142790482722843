export const newsletterData: Array<{ localeId: string; iconUrl: string }> = [
	{
		localeId: 'catalog.home.newsletter.account',
		iconUrl: 'https://storage.googleapis.com/bk-fms-eu/public/static/icon-account.svg'
	},
	{
		localeId: 'catalog.home.newsletter.rss',
		iconUrl: 'https://storage.googleapis.com/bk-fms-eu/public/static/icon-rss.svg'
	},
	{
		localeId: 'catalog.home.newsletter.user',
		iconUrl: 'https://storage.googleapis.com/bk-fms-eu/public/static/icon-user.svg'
	},
	{
		localeId: 'catalog.home.newsletter.devices',
		iconUrl: 'https://storage.googleapis.com/bk-fms-eu/public/static/icon-devices.svg'
	}
];
