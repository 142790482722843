const dimensionsRegex = /_\d{2,4}x\d{2,4}/;

type ImageParams = {
	width: number;
	height: number;
};

/**
 * Accepts an image URL and appends the correct query parameters to it to manipulate the size. Currently, this is only used for Vimeo images that support the image manipulation through URL search query params.
 *
 * The Vimeo docs that support this are here: https://developer.vimeo.com/api/upload/thumbnails
 *
 * @param url The image URL to manipulate, for example: https://i.vimeocdn.com/video/123456789_640.jpg
 * @returns The manipulated image URL, for example: https://i.vimeocdn.com/video/123456789_640x360.jpg
 */
export const buildImageURL = (url: string, { width, height }: ImageParams) => {
	let newUrl = url;

	if (newUrl.includes('i.vimeocdn.com')) {
		if (!newUrl.match(dimensionsRegex)) {
			newUrl += `_${width}x${height}`;
		} else {
			newUrl = newUrl.replace(dimensionsRegex, `_${width}x${height}`);
		}
	}

	return newUrl;
};
