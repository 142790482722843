import { Types } from 'common';

const getTopicFilter = (topicCode?: string | null) => {
	if (!topicCode) {
		return null;
	}
	const topicFilter: Types.SearchQuery['filters'] = [
		{
			field: 'topics.code',
			operation: Types.SearchFilterOperation.Equal,
			value: [topicCode]
		}
	];

	return topicFilter;
};

export default getTopicFilter;
