import React from 'react';
import { Types } from 'common';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	speakerText: {
		fontSize: 14,
		fontWeight: 600,
		color: theme.palette.grey[500],
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-box-orient': 'vertical',
		'-webkit-line-clamp': 2
	}
}));

type MediathekVideoSpeakersProps = {
	speakers: Array<Types.Speaker>;
};

export const MediathekVideoSpeakers = ({ speakers }: MediathekVideoSpeakersProps) => {
	const classes = useStyles();

	if (!speakers?.length) {
		return null;
	}

	const topSpeaker = speakers[0];
	let topSpeakerText = '';
	topSpeakerText += topSpeaker?.title ? `${topSpeaker?.title} ` : '';
	topSpeakerText += topSpeaker?.name ? `${topSpeaker?.name}` : '';

	const otherSpeakersCount = speakers.length - 1 > 0 ? ` +${speakers.length - 1}` : '';

	return <span className={classes.speakerText}>{topSpeakerText + otherSpeakersCount}</span>;
};
