import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton, ThemeTypes } from 'components';
import { VIDEO_THUMBNAIL_HEIGHT, VIDEO_THUMBNAIL_WIDTH } from './VideoThumbnail';

const useStyles = makeStyles<ThemeTypes.Theme>((theme) => ({
	container: {
		width: VIDEO_THUMBNAIL_WIDTH,
		marginInlineStart: theme.spacing(1.5),
		...theme.border({ color: theme.palette.body['400'], radius: 6 })
	},
	image: {
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6
	},
	content: {
		position: 'relative',
		padding: theme.spacing(1)
	},
	footer: {
		position: 'absolute',
		bottom: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		right: theme.spacing(3),
		left: theme.spacing(3)
	}
}));

type Props = {
	contentHeight?: number;
};
const SkeletonMediathekVideoItem = ({ contentHeight = 145 }: Props) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Skeleton
				className={classes.image}
				variant="rectangular"
				width="100%"
				height={VIDEO_THUMBNAIL_HEIGHT}
			/>
			<Box className={classes.content} minHeight={contentHeight}>
				<Box marginTop={1.5}>
					<Skeleton variant="rectangular" width="100%" height={21} />
				</Box>
				<Box marginTop={0.5}>
					<Skeleton variant="rectangular" width="100%" height={21} />
				</Box>
				<Box marginTop={0.5}>
					<Skeleton
						variant="rectangular"
						width={VIDEO_THUMBNAIL_WIDTH / 1.5}
						height={21}
					/>
				</Box>
				<Box marginTop={1}>
					<Skeleton variant="rectangular" width={VIDEO_THUMBNAIL_WIDTH / 2} height={18} />
				</Box>
			</Box>
		</div>
	);
};

export default SkeletonMediathekVideoItem;
