import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { hasGroupMembership, useMembershipContext, useUserContext } from 'common';

import MediathekAddons from './UserWelcomeHeader/MediathekAddons';
import { useRecentlyStartedCoursesQuery } from '../../../graphql/catalog/queries/queries.generated';
import MediathekStartedOnDemandCourses from './MediathekStartedOnDemandCourses';
import UserWelcomeHeader from './UserWelcomeHeader/UserWelcomeHeader';
import { useIsOnDemandAccreditationEnabled } from '../../../hooks/useIsOnDemandAccreditationEnabled';

const useStyles = makeStyles((theme) => ({
	addonsContainer: {
		width: '100%',
		flexBasis: '100%',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),

		[theme.breakpoints.up('md')]: {
			order: 2,
			marginTop: 0,
			marginBottom: theme.spacing(7)
		}
	}
}));

export const MediathekHeader = () => {
	const classes = useStyles();
	const { isLoading: isLoadingUser } = useUserContext();
	const onDemandAccreditationEnabled = useIsOnDemandAccreditationEnabled();

	const { userMembership, loading: isLoadingMembership } = useMembershipContext();
	const { data: coursesData } = useRecentlyStartedCoursesQuery({
		skip: !onDemandAccreditationEnabled
	});
	const userHasGroupMembership = hasGroupMembership(userMembership);
	const hasStartedSomeOnDemandCourse = Boolean(
		onDemandAccreditationEnabled && coursesData?.recentlyStartedCourses?.length
	);

	if (isLoadingUser || isLoadingMembership) {
		return null;
	}

	if (userHasGroupMembership || hasStartedSomeOnDemandCourse) {
		return (
			<>
				<UserWelcomeHeader />
				{userHasGroupMembership && <MediathekAddons className={classes.addonsContainer} />}
				{hasStartedSomeOnDemandCourse && (
					<MediathekStartedOnDemandCourses className={classes.addonsContainer} />
				)}
			</>
		);
	}

	return <UserWelcomeHeader />;
};
