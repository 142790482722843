import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { ColoredIcon, NextLink } from 'common';
import { Icon } from 'components';

const useStyles = makeStyles((theme) => ({
	topicTitleContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		gap: 24,
		marginInline: 24,
		[theme.breakpoints.down('sm')]: {
			gap: 8
		}
	},
	topicTitleIconContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: 24,
		[theme.breakpoints.down('sm')]: {
			gap: 8
		}
	},
	topicTitleText: {
		...theme.typography.h3,
		fontWeight: 600,
		color: theme.palette.primary.main
	},
	topicIcon: {
		width: 56,
		height: 56,
		aspectRatio: '1',

		[theme.breakpoints.down('sm')]: {
			width: 32,
			height: 32
		}
	},
	showAllUrl: {
		...theme.typography.linkXSmall,
		color: theme.palette.secondary.main,
		fontFamily: 'Roboto',
		fontWeight: 500,
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(1),
		lineHeight: 'normal',
		'&:hover': {
			textDecoration: 'underline'
		}
	}
}));

interface TopicTitleProps {
	text: string | undefined;
	showAllUrl: string | null;
	iconUrl: string | null | undefined;
	iconColor: string;
}

export const TopicTitle = (props: TopicTitleProps) => {
	const { iconUrl, iconColor, text, showAllUrl } = props;
	const classes = useStyles();
	const { formatMessage } = useIntl();

	if (!text) {
		return null;
	}

	return (
		<h3 className={classes.topicTitleContainer}>
			<span className={classes.topicTitleIconContainer}>
				{iconUrl && (
					<ColoredIcon url={iconUrl} color={iconColor} className={classes.topicIcon} />
				)}
				<span className={classes.topicTitleText}>{text}</span>
			</span>
			{showAllUrl && (
				<NextLink href={showAllUrl} className={classes.showAllUrl}>
					{formatMessage({ id: 'catalog.home.section-title.show-all' })}
					<Icon icon="arrowRight" />
				</NextLink>
			)}
		</h3>
	);
};
