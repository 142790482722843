import makeStyles from '@mui/styles/makeStyles';
import { theme } from 'common';
import type { ThemeTypes } from 'components';

export const dimensions = {
	small: {
		container: {
			height: 348,
			width: 285,
			margin: theme.spacing(0, 0, 0, 1.5)
		},
		image: {
			height: 163,
			width: 283
		}
	},
	large: {
		container: {
			height: 360,
			width: 309,
			margin: theme.spacing(0, 0, 0, 1.5)
		},
		image: {
			height: 165,
			width: 307
		}
	}
};

const useStyles = makeStyles<ThemeTypes.Theme>(
	(theme) => ({
		container: {
			position: 'relative',
			margin: dimensions.small.container.margin,
			height: dimensions.small.container.height,
			width: dimensions.small.container.width,
			...theme.border({ radius: 6, color: theme.palette.body.light }),
			cursor: 'pointer',
			'&:hover $name': {
				textDecoration: 'underline'
			},
			[theme.breakpoints.up('xl')]: {
				margin: dimensions.large.container.margin,
				height: dimensions.large.container.height,
				width: dimensions.large.container.width
			}
		},
		tooltip: {
			backgroundColor: '#F6F6F6',
			border: '1px solid rgba(0, 0, 0, 0.12)',
			color: '#59748D',
			fontWeight: 400,
			fontSize: 14
		},
		imageContainer: {
			position: 'relative',
			height: dimensions.small.image.height,
			[theme.breakpoints.up('xl')]: {
				height: dimensions.large.image.height
			}
		},
		image: {
			height: dimensions.small.image.height,
			width: dimensions.small.image.width,
			objectFit: 'cover',
			backgroundColor: theme.palette.accent.main,
			borderTopLeftRadius: 6,
			borderTopRightRadius: 6,
			[theme.breakpoints.up('xl')]: {
				height: dimensions.large.image.height,
				width: dimensions.large.image.width
			}
		},
		duration: {
			backgroundColor: theme.palette.body.dark,
			color: theme.palette.common.white,
			position: 'absolute',
			bottom: theme.spacing(1),
			right: theme.spacing(1),
			padding: theme.spacing(0, 0.5),
			fontWeight: 'bold',
			fontSize: 12,
			...theme.border({ radius: 6, color: theme.palette.body.light })
		},
		content: {
			padding: theme.spacing(2),
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			height: `calc(${dimensions.small.container.height}px - ${dimensions.small.image.height}px)`,
			[theme.breakpoints.up('xl')]: {
				height: `calc(${dimensions.large.container.height}px - ${dimensions.large.image.height}px)`
			}
		},
		name: {
			fontWeight: 600,
			lineHeight: 'normal',
			fontSize: 16,
			marginBottom: theme.spacing(2),
			letterSpacing: 'normal',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			'-webkit-box-orient': 'vertical',
			'-webkit-line-clamp': 3
		},
		footer: {
			display: 'flex',
			justifyContent: 'space-between',
			bottom: theme.spacing(0.5),
			width: `calc(100% - ${theme.spacing(2)}px)`
		},
		brand: {
			textTransform: 'uppercase',
			fontWeight: 500
		},
		brandContainer: {
			justifyContent: 'center',
			height: '100%'
		},
		webUpImage: {
			height: '100%',
			width: '100%',
			borderRadius: 3,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		brandNameWebinar: {
			display: 'none'
		},
		brandImageWebinar: {
			height: 80,
			width: 80,
			borderTopLeftRadius: 3,
			borderTopRightRadius: 3,
			color: theme.palette.label.main
		},
		freeBadge: {
			position: 'absolute',
			top: theme.spacing(2),
			right: theme.spacing(2)
		},
		badgeContainer: { display: 'flex' }
	}),
	{ name: 'WebUpItem' }
);

export default useStyles;
