import React, { useRef } from 'react';
import { Types, formatters, useIntersectionObserver } from 'common';
import makeStyles from '@mui/styles/makeStyles';
import { buildImageURL } from '../../utils/buildImageURL';
import { catalogTracking } from '../../utils/segment';
import { useIntl } from 'react-intl';

export const VIDEO_THUMBNAIL_WIDTH = 243;
export const VIDEO_THUMBNAIL_HEIGHT = 136;

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'relative',
		lineHeight: 0,
		userSelect: 'none',
		...theme.border({ kind: 'bottom', color: theme.palette.body['400'] })
	},
	thumbnail: {
		width: '100%',
		height: VIDEO_THUMBNAIL_HEIGHT,
		boxSizing: 'border-box',
		borderRadius: '6px 6px 0 0'
	},
	durationContainer: {
		position: 'absolute',
		backgroundColor: `${theme.palette.common.black}B2`,
		color: theme.palette.common.white,
		bottom: 6,
		right: theme.spacing(1),
		padding: '1px 3px',
		lineHeight: '14px',
		fontWeight: 'bold',
		fontSize: 12,
		...theme.border({ radius: 3, color: theme.palette.body.light })
	},
	freeBadge: {
		position: 'absolute',
		top: 10,
		right: 10,
		width: 28,
		height: 28
	}
}));

type VideoThumbnailProps = Pick<Types.ContentDocument, 'source' | 'contentId'> & {
	displayLockIcon: boolean;
	alt?: string;
	index: number | undefined;
};

export const VideoThumbnail = (thumbnailProps: VideoThumbnailProps) => {
	const { contentId, source, displayLockIcon, alt = '', index } = thumbnailProps;
	const classes = useStyles();
	const { locale } = useIntl();

	const imageUrl = buildImageURL(source?.thumbnail ?? '', {
		width: VIDEO_THUMBNAIL_WIDTH,
		height: VIDEO_THUMBNAIL_HEIGHT
	});
	const altText = alt || '';
	const duration = formatters.formatContentDuration(source?.duration);

	const containerRef = useRef<HTMLDivElement | null>(null);
	useIntersectionObserver(containerRef, {}, () => {
		catalogTracking.videoThumbnailViewed(contentId, { locale, index });
	});

	return (
		<div className={classes.imageContainer} ref={containerRef}>
			<img className={classes.thumbnail} src={imageUrl} alt={altText} />
			<div className={classes.durationContainer}>{duration}</div>
			{displayLockIcon && (
				<img
					data-testid="free-badge"
					src="https://bk-public-prod.storage.googleapis.com/public/static/icon-unlock-free-content.svg"
					className={classes.freeBadge}
				/>
			)}
		</div>
	);
};
