import React, { useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, Icon } from 'components';
import { Box } from '@mui/material';
import {
	booleanFilter,
	useRouter,
	getCountryCode,
	isFeatureEnabled,
	useHydrationContext,
	Fragments
} from 'common';

import { getTopicsSearchQuery } from '../MediathekHome.utils';
import { useSearchForTopicsQuery } from '../../../graphql/catalog/queries';
import { useIsContentAvailable } from '../../../hooks';
import TopicRenderer from './TopicRenderer';
import { uniqBy } from 'lodash';
import FiltersUtils from '../../../components/Filters/Filters.utils';
import { getHomePageTopicVideosQuery } from './queries/HomePageTopicVideosQuery';

export type LecturesQueryParams = {
	language?: Language | null;
};

type ContentsByTopicsProps = {
	selectedTopicCode: string;
	renderItem: (item: Fragments.ContentDocumentSearchFieldsFragment, index: number) => JSX.Element;
};

export const ContentsByTopics = (props: ContentsByTopicsProps) => {
	const { selectedTopicCode, renderItem } = props;

	const [isLecturesDataAvailable, setIsLecturesDataAvailable] = useState(false);
	const isContentAvailable = useIsContentAvailable();
	const [isContentAvailableLoading, setIsContentAvailableLoading] = useState(false);
	const { locale, formatMessage } = useIntl();
	const countryCode = getCountryCode(locale);
	const languageDropdownOptions = FiltersUtils.getDomainLanguageOptions(countryCode);
	const isLanguageFilterAvailable =
		isFeatureEnabled('contentLanguageSwitch', countryCode) &&
		languageDropdownOptions.length > 0;
	const { isHydrated } = useHydrationContext();
	const router = useRouter<LecturesQueryParams>();
	const {
		query: { language = null },
		setQuery
	} = router;

	const handleChangeLanguage = (newLanguage: string | null) => {
		const lang =
			newLanguage === 'ALL' ? null : (newLanguage as LecturesQueryParams['language']);
		setQuery({ language: lang });
	};

	const { data, loading } = useSearchForTopicsQuery({
		variables: getTopicsSearchQuery({
			levels: ['0', '1']
		})
	});

	const { data: selectedTopicData } = useSearchForTopicsQuery({
		variables: getTopicsSearchQuery({
			filters: [
				{
					field: 'code',
					value: [selectedTopicCode]
				}
			]
		})
	});

	/**
	 * After we display the main (selected) topic, we need to display all its subtopics below, and only after that we can display other related topics. This query fetches the subtopics.
	 */
	const { data: selectedTopicSubtopicsData } = useSearchForTopicsQuery({
		variables: getTopicsSearchQuery({
			filters: [
				{
					field: 'parents.code',
					operation: 'CONTAIN',
					value: [selectedTopicCode]
				}
			]
		})
	});

	const selectedTopic = selectedTopicData?.topicDocuments.data
		? selectedTopicData.topicDocuments.data[0]
		: null;

	const topics = useMemo(
		() =>
			uniqBy(
				[
					selectedTopic,
					...(selectedTopicSubtopicsData?.topicDocuments.data ?? []),
					...(data?.topicDocuments.data ?? [])
				].filter(booleanFilter),
				'id'
			),
		[data, selectedTopicData]
	);

	useEffect(() => {
		const checkIfDataIsAvailable = async () => {
			const topicCodes = [selectedTopicCode];
			topics?.forEach((topic) => topicCodes.push(topic.code));
			if (topicCodes.length) {
				if (!isContentAvailableLoading) {
					setIsContentAvailableLoading(true);
					const isDataAvailable = await isContentAvailable(
						getHomePageTopicVideosQuery({
							countryCode,
							pageNumber: 0,
							topics: topicCodes,
							isMainTopic: false,
							childCodes: [],
							language
						})
					);
					setIsLecturesDataAvailable(isDataAvailable);
					setIsContentAvailableLoading(false);
				}
			}
		};
		checkIfDataIsAvailable();
	}, [selectedTopic, topics, countryCode]);

	const children = useMemo(() => {
		if (!isLecturesDataAvailable) {
			return null;
		}
		if (!topics?.length && selectedTopic) {
			return (
				<TopicRenderer
					topic={selectedTopic}
					key={selectedTopic.id}
					language={language}
					isMainTopic
					childCodes={[]}
					renderItem={renderItem}
				/>
			);
		}
		const childCodes = data?.topicDocuments.data?.map((each) => each.code) ?? [];
		return (
			<>
				{topics?.map((topic) => (
					<TopicRenderer
						topic={topic}
						language={language}
						key={topic.id}
						isMainTopic={topic.id === selectedTopic?.id}
						childCodes={childCodes}
						renderItem={renderItem}
					/>
				))}
			</>
		);
	}, [topics, isLecturesDataAvailable, selectedTopic, language]);

	if ((!isLecturesDataAvailable && !loading) || !isHydrated) {
		return null;
	}

	return (
		<Box data-testid="topic-contents-videos-container">
			{isLanguageFilterAvailable && isHydrated && (
				<Box m={2}>
					<Dropdown
						options={languageDropdownOptions}
						value={language}
						title={
							<>
								<Icon icon="language" />
								{formatMessage({ id: 'filters.language.title' })}
							</>
						}
						onChange={handleChangeLanguage}
						startIcon={<Icon icon="language" />}
					/>
				</Box>
			)}
			{children}
		</Box>
	);
};
