import { useIntl } from 'react-intl';
import { formatters, Types } from 'common';

export const useGetOnDemandCourseTitle = () => {
	const { formatMessage, locale } = useIntl();

	return (productName: Types.Scalars['JSON'] | null) => {
		const translatedProductName =
			formatters.formatTranslation(productName, {
				locale
			}) || '';
		const courseTitle = `${translatedProductName} ${formatMessage({
			id: 'catalog.on-demand.banner.on-demand-cme-fortbildung'
		})}`;

		return courseTitle;
	};
};
