import { CATALOG_URLS, Types, buildURLWithParams, generateContentURL, useChangeURL } from 'common';
import { useCallback } from 'react';

interface BuildPartnerContentURLProps {
	slug?: string | null;
	providerId?: string | null;
	contentId: string;
}

export const buildPartnerContentURL = ({
	slug,
	providerId,
	contentId
}: BuildPartnerContentURLProps) => {
	const newUrl = buildURLWithParams(CATALOG_URLS.partnerVideo, [
		slug || providerId || '',
		'all',
		contentId
	]);

	return newUrl;
};

type UseOnPartnerContentClickProps = {
	isInTheFuture?: boolean;
	brand?: Types.ProductBrand | null;
} & BuildPartnerContentURLProps;

/**
 * @returns {Function} onClick function for partner content
 * Useful to navigate to partner content page relying on the provider `slug` or `providerId` as fallback.
 *
 * @deprecated Don't use onClick functions to change the URL, instead generate the URL and pass it to the Link component for better accessibility.
 */
const useOnPartnerContentClick = () => {
	const changeURL = useChangeURL();
	const onClickFn = useCallback(
		({ isInTheFuture, brand, contentId, slug, providerId }: UseOnPartnerContentClickProps) => {
			if (isInTheFuture && brand !== Types.ProductBrand.Summedup) {
				const contentUrl = generateContentURL({ contentId });
				changeURL(contentUrl, 'catalog', true);
			} else {
				const partnerContentUrl = buildPartnerContentURL({ slug, providerId, contentId });
				changeURL(partnerContentUrl, 'catalog', true);
			}
		},
		[]
	);
	return onClickFn;
};

export default useOnPartnerContentClick;
