import Dayjs from 'dayjs';
import { Types } from 'common';
import { formatDateForDB } from 'components';
import { ContentContentTypeFilter, ContentContentTypeFilterType } from '../../components/Filters';

const getContentsDefinitionStartDateFilter = (
	contentTypeFilter?: ContentContentTypeFilterType
): Types.SearchQuery['filters'] => {
	if (contentTypeFilter === ContentContentTypeFilter.GUIDELINE) {
		return [];
	}
	// Definition for contents is 14 months before the current time
	const startDefinitionDate = Dayjs().startOf('day').add(-14, 'months');
	return [
		{
			field: 'endDateTime',
			operation: Types.SearchFilterOperation.GreaterThanEqual,
			value: [formatDateForDB(startDefinitionDate)],
			valueWithNull: true
		}
	];
};

export default getContentsDefinitionStartDateFilter;
