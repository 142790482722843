import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from 'components';

export const createDimensions = (theme: ThemeTypes.Theme) => ({
	small: {
		container: {
			width: 240,
			margin: theme.spacing(0, 0, 0, 1.5)
		},
		image: {
			width: 240
		}
	},
	large: {
		container: {
			width: 263,
			margin: theme.spacing(0, 0, 0, 1.5)
		},
		image: {
			width: 260
		}
	}
});

const useStyles = makeStyles(
	(theme) => {
		const dimensions = createDimensions(theme);
		return {
			container: {
				margin: dimensions.small.container.margin,
				width: dimensions.small.container.width,
				cursor: 'pointer',
				'&:hover $title': {
					textDecoration: 'underline'
				},
				[theme.breakpoints.up('xl')]: {
					margin: dimensions.large.container.margin,
					maxWidth: dimensions.large.container.width
				},
				...theme.border({ color: theme.palette.body['400'], radius: 6 })
			},
			imageContainer: {
				position: 'relative',
				marginBottom: theme.spacing(1.5),
				'& img': {
					borderRadius: '6px 6px 0 0 !important'
				},
				...theme.border({ kind: 'bottom', color: theme.palette.body['400'] })
			},
			image: {
				width: '100%',
				height: '100%',
				objectFit: 'cover',
				...theme.border({ color: 'transparent' })
			},
			title: {
				wordBreak: 'break-word',
				fontSize: 16,
				lineHeight: '18.75px',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				'-webkit-box-orient': 'vertical',
				'-webkit-line-clamp': 2
			},
			sponsorLogo: {
				maxHeight: 20,
				objectFit: 'contain'
			},
			playerContainer: {
				width: '100%',
				position: 'relative',
				pointerEvents: 'none',
				'& .react-player__shadow': {
					display: ['none', '!important']
				}
			},
			duration: {
				backgroundColor: `${theme.palette.common.black}B2`,
				color: theme.palette.common.white,
				position: 'absolute',
				bottom: 6,
				right: theme.spacing(1),
				padding: '1px 3px',
				lineHeight: '14px',
				fontWeight: 'bold',
				fontSize: 12,
				...theme.border({ radius: 3, color: theme.palette.body.light })
			},
			freeBadge: {
				position: 'absolute',
				top: theme.spacing(1),
				right: theme.spacing(1),
				'& > svg': {
					fill: theme.palette.primary.main
				}
			},
			progress: {
				marginLeft: theme.spacing(2),
				marginRight: theme.spacing(2),
				height: 6,
				borderRadius: 3,
				marginBottom: theme.spacing(1),
				backgroundColor: theme.palette.body[400]
			},
			subtitle: {
				fontSize: 14,
				fontStyle: 'normal',
				fontWeight: 500,
				lineHeight: '18.75px',
				letterSpacing: '0.75px',
				textAlign: 'left',
				color: theme.palette.label.main,
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				'-webkit-box-orient': 'vertical',
				'-webkit-line-clamp': 2
			},
			descriptionContainer: {
				display: 'flex',
				flexDirection: 'column',
				padding: theme.spacing(1, 2, 2, 2),
				gap: theme.spacing(0.5),
				height: 124
			}
		};
	},
	{ name: 'LectureItemWithProgress' }
);

export default useStyles;
