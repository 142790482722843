import React from 'react';
import { useIntl } from 'react-intl';
import { CATALOG_URLS, buildURLWithParams } from 'common';
import { SquareLink } from '../../../components/SquareLink/SquareLink';

export const ShowAllCoursesButton = ({ totalCount }: { totalCount?: number | null }) => {
	const { formatMessage } = useIntl();
	const URL = buildURLWithParams(CATALOG_URLS.product, ['all']);

	// Round the total number down to the nearest multiple of 5
	const getAllCoursesCount = (totalCount?: number | null) => {
		if (!totalCount) {
			return formatMessage({ id: 'media-library.home.all' });
		}
		const allCourses = totalCount - (totalCount % 5);
		return `${allCourses}+`;
	};

	return (
		<SquareLink URL={URL}>
			{formatMessage(
				{ id: 'media-library.home.see-all-courses' },
				{ count: getAllCoursesCount(totalCount) }
			)}
		</SquareLink>
	);
};
