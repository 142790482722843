import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from 'components';

const useStyles = makeStyles<ThemeTypes.Theme>(
	(theme) => ({
		sectionTitle: {
			marginTop: 80,
			marginBottom: theme.spacing(3),
			'&:nth-of-type(1)': {
				marginTop: 44
			}
		},
		horizontalListContainer: {
			[theme.breakpoints.up('lg')]: {
				marginLeft: theme.spacing(-3)
			}
		},
		text: {
			...theme.typography.displayXSmall
		}
	}),
	// to have more priority over SectionTitle text
	{ name: 'ContentsByTopics', index: 10 }
);

export default useStyles;
