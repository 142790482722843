import { Types } from 'common';
import { DateFilterOptionEnum } from '../filters/dateFilter';

/**
 * This function is useful to build the orders for the query.
 */
const getOrders = ({
	contentType,
	date,
	topicCode,
	orders
}: {
	contentType?: Types.ContentType;
	date?: DateFilterOptionEnum | null;
	topicCode?: string | null;
	orders?: Types.SearchQuery['orders'];
}): Types.SearchQuery['orders'] => {
	const orderByStartDate = getDefaultOrder(date);
	const newOrders = [];
	// if there is no topic selected, there should be no order for courses
	// so the backend will order them based on the topics properly
	// but if there is a topic selected, they should be ordered by date
	if (contentType === Types.ContentType.Course && topicCode) {
		newOrders.push(orderByStartDate);
	}
	if (contentType === Types.ContentType.Webinar) {
		newOrders.push(orderByStartDate);
	}
	return [...newOrders, ...(orders || [])];
};

export const getDefaultOrder = (date?: DateFilterOptionEnum | null): string => {
	const orderByStartDate =
		date === DateFilterOptionEnum.ONDEMAND ? '-startDateTime' : 'startDateTime';
	return orderByStartDate;
};

export default getOrders;
